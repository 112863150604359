<template>
    <div class="modal fade" :id="'deleteOwnerModal' + owner.owner_id" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Διαγραφή Δικαιούχου</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Είστε σίγουροι ότι θέλετε να διαγράψετε τον δικαιούχο "{{ owner.name }}";
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        @click="deleteOwner()">Διαγραφή</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'DeleteOwner',
        props: {
            permitNumber: Number,
            year: Number,
            owner: Object,
            index: Number
        },
        methods: {
            deleteOwner() {
                const data = {
                    "year": this.year,
                    "p_number": this.permitNumber,
                    "owner": this.owner
                };

                axios
                    .delete(process.env.VUE_APP_SERVER + 'api/delete_owner.php', {data: data})
                    .then(() => {
                        this.$emit('ownerDelete', this.index);
                    })
                    .catch(error => console.log(error));
            }
        },
    }
</script>
