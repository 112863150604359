<template>
    <div class="container bg-light pb-4">
        <div class="text-end pt-4">
            <button type="button" class="btn btn-info" @click="clear()">Καθάρισμα</button>
        </div>
        <div class="text-center pt-4 h5">
            <strong>Δημιουργία νέας άδειας</strong>
        </div>
        <form @submit.prevent="createNewPermit()" autocomplete="off">
            <div class="row mt-3">
                <div class="col-md-6">
                    <label for="permitNumber" class="form-label">Αριθμός Άδειας <span
                            class="text-danger">*</span></label>
                    <input id="permitNumber" class="form-control" type="text" pattern="[0-9]{1,4}"
                        title="Ο αριθμός άδειας πρέπει να αποτελείται από 1-4 ψηφία"
                        oninvalid="this.setCustomValidity('Ο αριθμός άδειας πρέπει να αποτελείται από 1-4 ψηφία')"
                        onchange="try{setCustomValidity('')}catch(e){}" v-model="metadata.p_number" required>
                </div>
                <div class="col-md-6">
                    <label for="year" class="form-label">Έτος Έκδοσης <span class="text-danger">*</span></label>
                    <input id="year" class="form-control" type="text" pattern="[0-9]{4}"
                        title="Το έτος πρέπει να αποτελείται απο 4 ψηφία"
                        oninvalid="this.setCustomValidity('Το έτος πρέπει να αποτελείται απο 4 ψηφία')"
                        onchange="try{setCustomValidity('')}catch(e){}" v-model="metadata.year" required>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <label for="municipality" class="form-label">Δημοτικό Διαμέρισμα <span
                            class="text-danger">*</span></label>
                    <input id="municipality" class="form-control" type="text"
                        @input="metadata.municipal_district = $event.target.value.toUpperCase()"
                        :value="metadata.municipal_district.toUpperCase()" required>
                </div>
                <div class="col-md-6">
                    <label for="position" class="form-label">Θέση</label>
                    <input id="position" class="form-control" type="text"
                        @input="metadata.position = $event.target.value.toUpperCase()"
                        :value="metadata.position.toUpperCase()">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <label for="validation" class="form-label">Θεώρηση</label>
                    <input id="validation" class="form-control" type="text"
                        @input="metadata.validation = $event.target.value.toUpperCase()"
                        :value="metadata.validation.toUpperCase()">
                </div>
            </div>
            <NewOwnerFields v-for="(owner, index) in owners" :key="index" :ownerProp="owner" :index="index"
                @saveChanges="saveChanges" @removeField="removeField" />
            <div class="mt-3">
                <button type="button" class="w-auto btn btn-primary" @click="addOwner()">
                    Προσθήκη δικαιούχου
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                </button>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <label for="files" class="form-label">Επισυνάψτε Αρχεία</label>
                    <input id="files" class="form-control" type="file" multiple="multiple" accept="application/pdf"
                        @change="filesSelected" ref="fileUpload">
                </div>
            </div>
            <NewPermitFiles :filesProp="files" v-if="files.length" @removeFile="removeFile" />
            <div class="text-center mt-3">
                <button type="submit" class="text-center btn btn-primary">Προσθήκη νέας άδειας</button>
            </div>
        </form>
        <div class="container alert alert-success mt-3" ref="created" v-show="permitCreated">
            Επιτυχής δημιουργία άδειας με αριθμό: <strong>{{ permitNumber }}</strong> και έτος έκδοσης: <strong>{{ year
                }}</strong>. Αν θέλετε να κάνετε επεξεργασία της άδειας πατήστε
            <router-link :to="{name: 'Home', query: {p_number: permitNumber, year: year}}">εδώ</router-link>.
        </div>
        <div class="alert alert-danger mt-3" role="alert" ref="exists" v-show="permitExists">
            Η άδεια με αριθμό: <strong>{{ permitNumber }}</strong> και έτος έκδοσης: <strong>{{ year }} </strong>
            υπάρχει ήδη. Αν θέλετε να κάνετε επεξεργασία της άδειας πατήστε
            <router-link :to="{name: 'Home', query: {p_number: permitNumber, year: year}}">εδώ</router-link>.
        </div>
        <div class="container alert alert-danger mt-3" ref="failure" v-show="creationFailure">
            Ανεπιτυχής δημιουργία άδειας με αριθμό: <strong>{{ permitNumber }}</strong> και έτος έκδοσης: <strong>{{
                year }}</strong>
        </div>
        <div class="container alert alert-danger mt-3" v-show="ownersEmpty">
            Πρέπει να προσθέσετε τουλάχιστον έναν δικαιούχο.
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import NewOwnerFields from '@/components/NewOwnerFields.vue';
    import NewPermitFiles from '@/components/NewPermitFiles.vue';

    export default {
        name: 'NewPermit',
        components: {
            NewOwnerFields,
            NewPermitFiles
        },
        data() {
            return {
                metadata: {
                    p_number: null,
                    year: null,
                    municipal_district: '',
                    position: '',
                    validation: '',
                },
                owners: [],
                files: [],
                permitNumber: null,
                year: null,
                permitCreated: false,
                permitExists: false,
                creationFailure: false,
                ownersEmpty: false,
            }
        },
        methods: {
            createNewPermit() {
                if (this.owners.length === 0) {
                    this.ownersEmpty = true;
                    return;
                }
                this.ownersEmpty = false;
                this.permitNumber = this.metadata.p_number;
                this.year = this.metadata.year;
                const data = {
                    metadata: this.metadata,
                    owners: this.owners,
                    files: this.files
                };
                axios
                    .post(process.env.VUE_APP_SERVER + "api/create.php", data)
                    .then(response => {
                        if (response.status === 200) {
                            this.permitCreated = false;
                            this.permitExists = true;
                            this.$nextTick(() => {
                                this.$refs.exists.scrollIntoView({behavior: 'smooth'})
                            });
                        }
                        else if (response.status === 201) {
                            this.permitExists = false;
                            this.permitCreated = true;
                            this.$nextTick(() => {
                                this.$refs.created.scrollIntoView({behavior: 'smooth'})
                            });
                        }
                        this.creationFailure = false;
                    })
                    .catch(() => {
                        this.creationFailure = true;
                        this.permitCreated = false;
                        this.permitExists = false;
                        this.$nextTick(() => {
                            this.$refs.failure.scrollIntoView({behavior: 'smooth'});
                        });
                    });
            },
            addOwner() {
                this.owners.push({
                    owner_name: '',
                    tax_id: '',
                    id_number: '',
                });
            },
            saveChanges(owner, index) {
                this.owners[index] = owner;
            },
            removeField(index) {
                this.owners.splice(index, 1);
            },
            removeFile(index) {
                this.files.splice(index, 1);
            },
            filesSelected(e) {
                for (var i = 0; i < e.target.files.length; i++) {
                    this.files[i] = new Object();
                    this.files[i].name = e.target.files[i].name;
                    this.files[i].filetype = 0;
                    var reader = new FileReader();

                    reader.onload = ((file) => {
                        return (e) => {
                            file.data = e.target.result;
                        }
                    })(this.files[i]);

                    reader.readAsDataURL(e.target.files[i]);
                }
            },
            clear() {
                this.metadata = {
                    p_number: null,
                    year: null,
                    municipal_district: '',
                    position: '',
                    validation: '',
                };
                this.owners = [];
                this.files = [];
                this.creationFailure = false;
                this.permitCreated = false;
                this.permitExists = false;
                this.ownersEmpty = false;
                this.$refs.fileUpload.value = null;
            }
        },
        created() {
            document.title = 'Πολεοδομία - Προσθήκη Άδειας';
        }
    }
</script>
