<template>
    <div class="modal fade" :id="'addModal' + year + permitNumber" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Προσθήκη Αρχείων</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="file" class="form-control" multiple="multiple" accept="application/pdf"
                        @change="filesSelected">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" id="liveToastBtn"
                        @click="addFiles()">Υποβολή</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'AddFiles',
        props: {
            index: Number,
            permitNumber: Number,
            year: Number,
            permitID: Number
        },
        data() {
            return {
                file: []
            }
        },
        methods: {
            filesSelected(e) {
                for (var i = 0; i < e.target.files.length; i++) {
                    this.file[i] = new Object();
                    this.file[i].name = e.target.files[i].name;
                    var reader = new FileReader();

                    reader.onload = ((file) => {
                        return (e) => {
                            file.data = e.target.result;
                        }
                    })(this.file[i]);

                    reader.readAsDataURL(e.target.files[i]);
                }
            },
            addFiles() {
                if (this.file.length === 0) return;
                const data = {
                    'permitNumber': this.permitNumber,
                    'year': this.year,
                    'files': this.file
                }
                axios
                    .post(process.env.VUE_APP_SERVER + 'api/add_files.php', data)
                    .then((response) => {
                            this.$emit('getFiles', response.data);
                    })
                    .catch((error) =>
                        console.log(error)
                    );
            }
        },
    }
</script>
