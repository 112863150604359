<template>
    <div class="modal fade" :id="'editMetadata' + year + permitNumber" data-bs-backdrop="static"
        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Τροποποίηση Άδειας {{ permitNumber }}-{{ year }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="row mt-2 modal-body">
                    <div class="col-md-6">
                        <label for="owner_name">Δημοτικό Διαμέρισμα</label>
                        <input id="owner_name" class="form-control bg-gray" type="text" autocomplete="off"
                            @input="metadata.municipal_district = $event.target.value.toUpperCase()"
                            :value="metadata.municipal_district.toUpperCase()">
                    </div>
                    <div class="col-md-6">
                        <label for="tax_id">Θέση</label>
                        <input id="tax_id" class="form-control bg-gray" type="text" autocomplete="off"
                            @input="metadata.position = $event.target.value.toUpperCase()"
                            :value="metadata.position.toUpperCase()">
                    </div>
                </div>
                <div class="row mt-2 modal-body">
                    <div class="col-md-6">
                        <label for="id_number">Θεώρηση</label>
                        <input id="id_number" class="form-control bg-gray" type="text" autocomplete="off"
                            @input="metadata.validation = $event.target.value.toUpperCase()"
                            :value="metadata.validation.toUpperCase()">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="editMetadata()">Υποβολή</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'EditMetadata',
        props: {
            year: Number,
            permitNumber: Number,
            municipalDistrict: String,
            position: String,
            validation: String
        },
        data() {
            return {
                metadata: {
                    municipal_district: '',
                    position: '',
                    validation: ''
                }
            }
        },
        methods: {
            editMetadata() {
                const data = {
                    'year': this.year,
                    'p_number': this.permitNumber,
                    'municipal_district': this.metadata.municipal_district,
                    'position': this.metadata.position,
                    'validation': this.metadata.validation
                };

                axios
                    .put(process.env.VUE_APP_SERVER + 'api/edit_metadata.php', data)
                    .then(() => {
                        this.$emit('editMetadata', this.metadata);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        },
        created() {
            this.metadata.municipal_district = this.municipalDistrict ? this.municipalDistrict : '';
            this.metadata.validation = this.validation ? this.validation : '';
            this.metadata.position = this.position ? this.position : '';

        }
    }
</script>

<style scoped>
    .bg-gray {
        background: #F3F4F6;
    }

    .bg-gray:focus {
        background: white;
    }
</style>
