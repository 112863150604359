import { createRouter, createWebHistory } from 'vue-router'
import SearchPermit from '../views/SearchPermit.vue'
import NewPermit from '../views/NewPermit.vue'
import NotFound from '../views/NotFound.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';


const routes = [
    {
        path: '/',
        name: 'Home',
        props: route => ({
            p_number: route.query.p_number,
            year: route.query.year,
            owner_name: route.query.owner_name
        }),
        component: SearchPermit
    },
    {
        path: '/new-permit',
        name: 'NewPermit',
        component: NewPermit
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
