<template>
    <div class="table-responsive card p-3 mt-3" v-if="files.length">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Επισυναπτόμενα Αρχεία</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(file, index) in files" :key="file">
                    <td class="align-middle">{{ file.name }}</td>
                    <td class="align-bottom" align="right">
                        <div class="row float-end">
                            <select id="filetype" class="form-select w-auto me-2" v-model="file.filetype">
                                <option selected value="0">Επιλέξτε...</option>
                                <option value="1">Φύλλο Άδειας</option>
                                <option value="2">Ξυλότυπος</option>
                                <option value="3">Κάτοψη</option>
                                <option value="4">Τοπογραφικό</option>
                                <option value="5">Τομές</option>
                            </select>
                            <button class="btn btn-danger w-auto" type="button" @click="removeFile(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash" viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fill-rule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'NewPermitFiles',
        props: {
            filesProp: Object
        },
        data() {
            return {
                files: [],
            }
        },
        methods: {
            removeFile(index) {
                this.$emit('removeFile', index);
            }
        },
        created() {
            this.files = this.filesProp;
        }
    }
</script>
