<template>
    <div class="container card mt-4">
        <div class="mt-3">
            <button class="btn btn-danger float-end" type="button" @click="removeField()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
                    viewBox="0 0 16 16">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
            </button>
            <div class="card-title h5"><strong>Δικαιούχος {{ index + 1}}</strong></div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <label for="fullName" class="form-label">Ονοματεπώνυμο <span class="text-danger">*</span></label>
                <input id="fullName" class="form-control" type="text"
                    @input="owner.owner_name = $event.target.value.toUpperCase()"
                    :value="owner.owner_name.toUpperCase()" @change="saveChanges()" required>
            </div>
            <div class="col-md-6">
                <label for="taxID" class="form-label">ΑΦΜ</label>
                <input id="taxID" class="form-control" type="text" v-model.trim="owner.tax_id" @change="saveChanges()">
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-md-6">
                <label for="id" class="form-label">Αριθμός Δελτίου Ταυτότητας</label>
                <input id="id" class="form-control" type="text" v-model.trim="owner.id_number" @change="saveChanges()">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NewOwnerFields',
        props: {
            ownerProp: Object,
            index: Number
        },
        data() {
            return {
                owner: {
                    owner_name: '',
                    tax_id: '',
                    id_number: '',
                }
            }
        },
        methods: {
            saveChanges() {
                this.$emit('saveChanges', this.owner, this.index);
            },
            removeField() {
                this.$emit('removeField', this.index);
            }
        },
        mounted() {
            this.owner = this.ownerProp;
        },
        updated() {
            this.owner = this.ownerProp;
        }
    }
</script>
