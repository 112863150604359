<template>
    <div class="container text-center">
        <div class="h2">
            Η σελίδα δεν βρέθηκε. <br>
            <router-link :to="{name: 'Home'}">Πίσω στην αρχική.</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>
