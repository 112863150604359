<template>
    <div class="modal fade" :id="'newOwner' + year + permitNumber" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Προσθήκη Δικαιούχου</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="row mt-2 modal-body">
                    <div class="col-md-6">
                        <label for="owner_name">Ονοματεπώνυμο Δικαιούχου</label>
                        <input id="owner_name" class="form-control bg-gray" type="text"
                        @input="owner.name = $event.target.value.toUpperCase()" :value="owner.name.toUpperCase()">
                    </div>
                    <div class="col-md-6">
                        <label for="tax_id">ΑΦΜ</label>
                        <input id="tax_id" class="form-control bg-gray" type="text" v-model="owner.tax_id">
                    </div>
                </div>
                <div class="row mt-2 modal-body">
                    <div class="col-md-6">
                        <label for="id_number">Αριθμός Δελτίου Ταυτότητας</label>
                        <input id="id_number" class="form-control bg-gray" type="text" v-model="owner.id_number">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="createOwner()">Προσθήκη</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'NewOwner',
        props: {
            year: Number,
            permitNumber: Number,
            index: Number
        },
        data() {
            return {
                owner: {
                    name: '',
                    tax_id: '',
                    id_number: ''
                }
            }
        },
        methods: {
            createOwner() {
                const data = {
                    'owner': this.owner,
                    'year': this.year,
                    'p_number': this.permitNumber,
                };

                axios
                    .post(process.env.VUE_APP_SERVER + 'api/create_owner.php', data)
                    .then((response) => {
                            this.owner.owner_id = response.data.owner_id;
                            this.$emit('newOwner', this.owner);
                            this.owner = {
                                name: '',
                                tax_id: '',
                                id_number: ''
                            }
                    })
                    .catch((error) => console.log(error));
            }
        },
    }
</script>
