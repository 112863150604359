<template>
    <div class="container">
        <div class="card p-3">
            <div class="col text-end w-100">
                <router-link :to="{ name: 'NewPermit'}">
                    <button class="w-auto btn btn-primary">Νέα Άδεια
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </button>
                </router-link>
            </div>
            <form @submit.prevent="searchForPermit()" autocomplete="off">
                <div class="row mt-3">
                    <div class="form-group col-md-6">
                        <label for="permitNumber">Aριθμός Άδειας</label>
                        <input id="permitNumber" class="form-control w-100" type="text"
                            title="Ο αριθμός άδειας πρέπει να αποτελείται από 1-4 ψηφία" pattern="^[0-9]{1,3}$"
                            v-model="permitNumber">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="year">Έτος Έκδοσης</label>
                        <input id="year" class="form-control w-100" type="text" pattern="^[0-9]{4}$"
                            title="Το έτος έκδοσης πρέπει να αποτελείται από 4 ψηφία" v-model="year">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group">
                        <label for="lastName">Επώνυμο Δικαιούχου</label>
                        <input id="lastName" class="form-control w-100" type="text"
                            @input="ownerName = $event.target.value.toUpperCase()" :value="ownerName.toUpperCase()">
                    </div>
                </div>
                <div class="text-end mt-3">
                    <button type="submit" class="text-center btn btn-primary">Αναζήτηση Άδειας</button>
                </div>
            </form>
        </div>
        <div class="d-flex justify-content-center mt-3" v-if="spinner">
            <div class="spinner-border" role="status"></div>
        </div>
        <PermitTable :infoProp="permit" :indexProp="index" v-for="(permit, index) in info" :key="permit" ref="table" />
        <div class="alert alert-danger mt-3" v-show="requestFailure">
            Δεν βρέθηκαν αποτελεσμάτα για την αναζήτησή σας
        </div>
        <div class="pb-4"></div>
    </div>
</template>

<script>
    import PermitTable from '@/components/PermitTable.vue';
    import axios from 'axios';

    export default {
        name: 'SearchPermit',
        components: {
            PermitTable
        },
        data() {
            return {
                permitNumber: '',
                year: '',
                ownerName: '',
                info: null,
                requestFailure: false,
                spinner: false
            }
        },
        methods: {
            searchForPermit() {
                if (this.permitNumber.length === 0 &&
                    this.year.length === 0 &&
                    this.ownerName.length === 0) return;

                axios
                    .get(process.env.VUE_APP_SERVER + 'api/search.php?permit_number=' + this.permitNumber + '&year=' + this.year + '&owner_name=' + this.ownerName)
                    .then(response => {
                        this.requestFailure = false;
                        this.spinner = false;
                        this.info = response.data;
                        this.$router.push({name: 'Home', query: {p_number: this.permitNumber, year: this.year, owner_name: this.ownerName}});
                    })
                    .catch(() => {
                        this.info = null;
                        this.spinner = false;
                        this.requestFailure = true;
                    });

                this.spinner = true;
            },
        },
        watch: {
            "$route": function (to) {
                if (to.fullPath == "/")
                    this.info = null;
            }
        },
        mounted() {
            document.title = 'Πολεοδομία - Αναζήτηση';
            this.permitNumber = this.$route.query.p_number ? this.$route.query.p_number : '';
            this.year = this.$route.query.year ? this.$route.query.year : '';
            this.ownerName = this.$route.query.owner_name ? this.$route.query.owner_name : '';
            this.searchForPermit();
        }
    }
</script>
