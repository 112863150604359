<template>
    <div class="modal fade" :id="'editModal' + info.owner_id" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Τροποποίηση Δικαιούχου {{ info.name }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="row mt-2 modal-body">
                    <div class="col-md-6">
                        <label for="owner_name">Ονοματεπώνυμο Δικαιούχου</label>
                        <input id="owner_name" class="form-control bg-gray" type="text" autocomplete="off"
                            @input="owner.name = $event.target.value.toUpperCase()" :value="owner.name.toUpperCase()">
                    </div>
                    <div class="col-md-6">
                        <label for="tax_id">ΑΦΜ</label>
                        <input id="tax_id" class="form-control bg-gray" type="text" autocomplete="off"
                            v-model="owner.tax_id">
                    </div>
                </div>
                <div class="row mt-2 modal-body">
                    <div class="col-md-6">
                        <label for="id_number">Αριθμός Δελτίου Ταυτότητας</label>
                        <input id="id_number" class="form-control bg-gray" type="text" autocomplete="off"
                            v-model="owner.id_number">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="submitChanges()">Υποβολή</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'EditModal',
        props: {
            info: Object,
            index: Number
        },
        data() {
            return {
                owner: null
            }
        },
        methods: {
            submitChanges() {
                axios
                    .put(process.env.VUE_APP_SERVER + 'api/update_owner.php', this.owner)
                    .then(() => {
                        this.$emit('updateOwner', this.index, this.owner);
                    })
                    .catch((error) => console.log(error));
            }
        },
        created() {
            this.owner = this.info
        }
    }
</script>

<style scoped>
    .bg-gray {
        background: #F3F4F6;
    }

    .bg-gray:focus {
        background: white;
    }
</style>
