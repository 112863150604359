<template>
    <div class="modal fade" :id="'deleteModal' + index + permitID" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Διαγραφή Αρχείου</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο;
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        @click="deletePermit()">Διαγραφή</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'DeleteModal',
        props: {
            index: Number,
            p_number: Number,
            year: Number,
            filename: String,
            permitID: Number
        },
        methods: {
            deletePermit() {
                const data = {
                    "year" : this.year,
                    "p_number" : this.p_number,
                    "filename" : this.filename
                };
                axios
                    .delete(process.env.VUE_APP_SERVER + 'api/delete_file.php', { data : data})
                    .then(() => {
                        this.$emit('notifyDelete', this.index);
                    })
                    .catch(error => console.log(error));
            }
        },
    }
</script>
