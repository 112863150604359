<template>
    <div class="container mt-2 h4">
        <router-link :to="{name: 'Home'}" style="text-decoration: none; color: #ADB5BD;">
            Άδειες Πολεοδομίας
        </router-link>
    </div>
    <router-view />
</template>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
    }
</style>
