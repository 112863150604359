<template>
    <div class="container card p-3 mt-5" v-if="info">
        <div class="mt-2">
            <div class="float-end">
                <button class="w-auto btn btn-success" data-bs-toggle="modal"
                    :data-bs-target="'#editMetadata' + info.year + info.p_number">
                    Τροποποίηση <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                </button>
            </div>
            <h5 class="card-title w-50"> <strong>Αριθμός Άδειας: {{info.p_number}} <br>
                    Έτος Έκδοσης: {{info.year}} </strong><br>
            </h5>
        </div>
        <div class="mb-3" v-if="info">
            <p class="card-text">Δημοτικό Διαμέρισμα: {{info.municipal_district}} <br>
                Θέση: {{info.position}} <br>
                Θεώρηση: {{info.validation}} </p>
        </div>
        <div class="mt-2 h5 w-50"><strong>Στοιχεία Δικαιούχων</strong></div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Όνομα</th>
                        <th scope="col">ΑΦΜ</th>
                        <th scope="col">ΑΔΤ</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(owner, index) in info.owners" :key="owner">
                        <td class="align-middle">{{ ownerNameStrip(owner.name) }}</td>
                        <td class="align-middle">{{ owner.tax_id }}</td>
                        <td class="align-middle">{{ owner.id_number }}</td>
                        <td align="right">
                            <button class="btn btn-success ms-1" data-bs-toggle="modal"
                                :data-bs-target="'#editModal' + owner.owner_id">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                </svg>
                            </button>
                            <button class="btn btn-danger ms-1" data-bs-toggle="modal"
                                :data-bs-target="'#deleteOwnerModal' + owner.owner_id" v-if="info.owners.length > 1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash" viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fill-rule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                            </button>
                        </td>
                        <EditModal @updateOwner="updateOwner" :info="owner" :index="index" />
                        <DeleteOwner @ownerDelete="updateOwnerList" :permitNumber="info.p_number" :year="info.year"
                            :owner="owner" :index="index" />
                    </tr>
                </tbody>
            </table>
            <div class="mt-3">
                <button type="button" class="w-auto btn btn-primary" data-bs-toggle="modal"
                    :data-bs-target="'#newOwner' + info.year + info.p_number">
                    Προσθήκη δικαιούχου
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                </button>
            </div>
            <div class="w-50 h5 mt-4"><strong>Επισυναπτόμενα Αρχεία</strong></div>
            <div class="table-responsive" v-if="info.files.length">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Όνομα αρχείου</th>
                            <th scope="col">Τύπος αρχείου</th>
                            <th scope="col" align="right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in info.files" :key="item">
                            <tr class="info">
                                <td class="align-middle"><a href="#" :id="'f' + index + info.permit_id"
                                        :download="item.filename"><a
                                            @click.prevent="getPDF(info.p_number, info.year, item.filename, index)">{{item.filename}}</a></a>
                                </td>
                                <td class="align-middle">{{ filetype[item.filetype] }}</td>
                                <td class="align-middle" align="right">
                                    <button class="btn btn-success ms-1" data-bs-toggle="modal"
                                        :data-bs-target="'#editFile' + index + info.permit_id">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-danger ms-1" data-bs-toggle="modal"
                                        :data-bs-target="'#deleteModal' + index + info.permit_id">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <EditFile @updateFile="updateFile" :index="index" :permitID="info.permit_id"
                                :filenameProp="item.filename" :typeProp="item.filetype ? parseInt(item.filetype) : 0" />
                            <DeleteModal @notifyDelete="getNewInfo" :p_number="info.p_number" :year="info.year"
                                :filename="item.filename" :index="index" :permitID="info.permit_id" />
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="mt-3">
                <div class="">
                    <button type="button" class="w-auto btn btn-primary" data-bs-toggle="modal"
                        :data-bs-target="'#addModal' + info.year + info.p_number">
                        Προσθήκη αρχείων
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <AddFiles @getFiles="updateFiles" v-if="info" :permitNumber="info.p_number" :year="info.year" :index="indexProp"
            :permitID="info.permit_id" />
        <NewOwner @newOwner="addOwner" :permitNumber="info.p_number" :year="info.year" :index="indexProp" />
        <EditMetadata @editMetadata="updateMetadata" :permitNumber="info.p_number" :year="info.year"
            :municipalDistrict="info.municipal_district" :position="info.position" :validation="info.validation" />
    </div>
</template>

<script>
    import axios from 'axios';
    import DeleteModal from '@/components/DeleteModal.vue';
    import DeleteOwner from '@/components/DeleteOwner.vue';
    import AddFiles from '@/components/AddFiles.vue';
    import EditModal from '@/components/EditModal.vue';
    import NewOwner from '@/components/NewOwner.vue';
    import EditMetadata from '@/components/EditMetadata.vue';
    import EditFile from '@/components/EditFile.vue';


    export default {
        name: 'PermitTable',
        components: {
            DeleteModal,
            EditModal,
            AddFiles,
            EditFile,
            NewOwner,
            EditMetadata,
            DeleteOwner
        },
        props: {
            indexProp: Number,
            infoProp: Object
        },
        data() {
            return {
                info: null,
                filetype: [
                    '',
                    'Φύλλο Άδειας',
                    'Ξυλότυπος',
                    'Κάτοψη',
                    'Τοπογραφικό',
                    'Τομές'
                ]
            }
        },
        methods: {
            getPDF(number, year, filename, index) {
                axios
                    .get(process.env.VUE_APP_SERVER + 'api/get_file.php?permit_number=' + number + '&year=' + year + '&filename=' + filename,
                        {responseType: 'blob'})
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                        var fURL = document.getElementById("f" + index + this.info.permit_id);
                        fURL.href = fileURL;
                        fURL.click();
                    })
                    .catch(error => console.log(error));
            },
            ownerNameStrip(owner_name) {
                if (owner_name.length > 55)
                    return owner_name.slice(0, 55) + '...';
                return owner_name;
            },
            getNewInfo(index) {
                this.info.files.splice(index, 1);
            },
            updateOwnerList(index) {
                this.info.owners.splice(index, 1);
            },
            updateOwner(index, owner) {
                this.info.owners[index] = owner;
            },
            updateFile(filename, filetype, index) {
                this.info.files[index].filename = filename.endsWith('.pdf') ? filename : filename + '.pdf';
                this.info.files[index].filetype = filetype;
            },
            updateFiles(data) {
                this.info.files = data;
            },
            addOwner(owner) {
                this.info.owners.push(owner);
            },
            updateMetadata(metadata) {
                this.info.municipal_district = metadata.municipal_district;
                this.info.position = metadata.position;
                this.info.validation = metadata.validation;
            }
        },
        mounted() {
            this.info = this.infoProp;
            this.info.p_number = parseInt(this.info.p_number, 10);
            this.info.year = parseInt(this.info.year, 10);
            this.info.permit_id = parseInt(this.info.permit_id, 10);
        },
    }
</script>
